<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.resourceData.name.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input
                            v-model="resourceData.name"
                            id="name"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.resourceData.name.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{row}"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea
                            v-model="resourceData.description"
                            id="description"
                        />
                    </b-form-group>
                    <b-form-group
                        v-if="emails"
                        :class="{row}"
                        :label="$t('forms.notificationEmails')"
                        label-for="notificationEmails"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="notificationEmails"
                            v-model="resourceData.notificationEmails"
                            taggable
                            multiple
                            push-tags
                        />

                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";

export default {
    props: {
        resource: {Object, required: true},
        resourceObject: {Object, required: true},
        baseUrl: {type: String, required: true},
        updateMessage: {type: String, required: true},
        createMessage: {type: String, required: true},
        action: {type: String, default: null},
        emails: {type: Boolean, default: true},
    },
    components: {
        vSelect
    },
    mixins: [ResourceUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            resourceData: {
                name: {required},
            },
        };
    },
    data() {
        return {
            resourceData: this.resourceObject,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.action === "add") {
                    this.create(this.resource, this.resourceData, this.$t(this.createMessage), null, this.success, this.error);
                }
                if (this.action === "update") {
                    this.$emit("clearAction")
                    this.update(this.resource, this.resourceData.id, this.resourceData, this.$t(this.updateMessage), null, this.success, this.error);
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(this.baseUrl);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

